<template>
  <cv-structured-list :condensed="true" class="shoppingCartList">
    <template slot="items">
      <cv-structured-list-item
        v-for="(item, index) in this.$store.state.cart"
        :key="`${index}`"
      >
        <cv-structured-list-data>
          <h4 class="category">{{ item.cat }}</h4>

          <h2>{{ item.descr }}</h2>

          <p><span class="text-uppercase">{{ item.brand }}</span> {{ item.size }}</p>

          <cv-row kind="condensed">
            <cv-column>
              <qtItemselector
                :currItem="index"
                :cartItemQty="item.qty"
                @incrementQty="incrementQty(index)"
                @decrementQty="decrementQty(index)"
                @setQty="setQty"
              />
            </cv-column>
            <cv-column>
              <cv-button @click="removeCartItem(index)" kind="tertiary"
                >Rimuovi</cv-button
              >
            </cv-column>
          </cv-row>
        </cv-structured-list-data>
      </cv-structured-list-item>
    </template>
  </cv-structured-list>
</template>

<script>
import ItemQtySelector from '../ItemQtySelector/ItemQtySelector';

export default {
  name: 'ShoppingCartList',
  components: { qtItemselector: ItemQtySelector },
  props: {},
  data() {
    return {};
  },
  methods: {
    close() {},
    incrementQty(idx) {
      this.$store.commit('incrementQty', idx);
    },
    decrementQty(idx) {
      this.$store.commit('decrementQty', idx);
    },
    setQty(data) {
      this.$store.commit('setQty', data);
    },
    removeCartItem(idx) {
      this.$store.commit('removeCartItem', idx);
    }
  }
};
</script>

<style lang="scss">
@use 'carbon-components/scss/globals/scss/spacing' as s;

.shoppingCartList {
  p {
    margin-bottom: s.$spacing-05;
  }

  .cv-structured-list-data > .cv-row > .cv-column:last-child {
    text-align: right;
    padding-right: s.$spacing-06;
  }

}
</style>
