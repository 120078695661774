<template>
  <div class="checkoutPage">
    
    <h1 class="bx--col">Riepilogo ordine</h1>
    
    <p
      class="bx--col bx--type-expressive-paragraph-01"
      v-if="Object.keys(this.$store.state.cart).length == 0"
    >
      Il tuo carrello è vuoto!
    </p>
    <shopping-cart-list v-else />
    <cv-button-set>
      <router-link to="/" custom v-slot="{ navigate }">
        <cv-button kind="secondary" size="lg" @click="navigate"
          >Indietro</cv-button
        >
      </router-link>
      <router-link to="/confirm-order" custom v-slot="{ navigate }">
        <cv-button
          :disabled="Object.keys($store.state.cart).length == 0"
          @click="navigate"
          size="lg"
          >Avanti</cv-button
        >
      </router-link>
    </cv-button-set>
  </div>
</template>

<script>
import ShoppingCartList from './ShoppingCartList';

export default {
  name: 'CheckoutPage',
  components: {
    ShoppingCartList,
  },
  mounted() {}
};
</script>

<style lang="scss">
@use 'carbon-components/scss/globals/scss/spacing' as s;

h1 { margin: s.$spacing-07 0; }

.checkoutPage {
  overflow: hidden;

  div.empty-cart-msg {
    margin-top: 20px;
    padding: 30px;
    text-align: center;
  }

  .cv-button-set {
    position: absolute;
    width: 100%;
    bottom: 0;

    .cv-button {
      max-width: none;
      flex: 0 1 50%;
    }
  }
}
</style>
